import {
  GetClientsQuery,
  GetClientsQueryVariables,
  useGetClientsQuery,
} from '../__generated__/getClients';
import {
  Pagination,
  PaginationArg,
  UsersPermissionsUserFiltersInput,
} from '../../../__generated__/types';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useLocalization } from '../../../localization';
import { getUserFullName } from '../../../utils/getUserFullName';

type UserPermission = NonNullable<GetClientsQuery['usersPermissionsUsers']>['data'][0];
type AttributesType = NonNullable<UserPermission['attributes']>;
type CustomerContactInfo = NonNullable<
  NonNullable<NonNullable<AttributesType['customer_contact_info']>['data']>['attributes']
>;
type GetUserFullNameParams = {
  attributes?: {
    username?: string | null;
    customer_contact_info?: {
      data?: {
        attributes?: {
          lastName?: string | null;
          firstName?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export interface ClientType {
  id?: string | null;
  userName?: string | null;
  type?: string | null;
  clientType?: string | null;
  primaryPhoneNumber?: string | null;
  phoneNumbers?: CustomerContactInfo['phoneNumbers'];
  deliveryAddress?: CustomerContactInfo['deliveryAddress'];
  carModel?: Array<string | undefined>;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  data?: GetUserFullNameParams;
}

export type PaginationClientsType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  clients: Array<ClientType>;
  pagination?: PaginationClientsType;
  isLoading?: boolean;
}

export type FiltersType = {
  searchStrings?: string;
  searchNumbers?: string;
};
interface PropsHook {
  sort?: string;
  filters?: FiltersType;
  pagination?: PaginationArg;
}

export function getVariablesClientQuery(locale: string): GetClientsQueryVariables {
  return {
    locale,
    filters: { blocked: { eq: false } },
    pagination: { page: 1, pageSize: PAGE_COUNT },
    sort: ['id:desc'],
  };
}

export const useGetClients = ({ pagination, filters, sort = 'id:desc' }: PropsHook): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const { data, loading } = useGetClientsQuery({
    variables: {
      locale: updateSelectLanguage as string,
      pagination,
      sort: [sort],
      filters: { blocked: { eq: false }, ...getFilters() },
    },
  });

  if (data?.usersPermissionsUsers?.data) {
    const { data: users } = data.usersPermissionsUsers;
    const clients = users.map(user => {
      return {
        id: user?.id || '',
        fullName: getUserFullName(user),
        userName: user.attributes?.username,
        primaryPhoneNumber: user.attributes?.phoneNumber,
        phoneNumbers: user.attributes?.customer_contact_info?.data?.attributes?.phoneNumbers,
        deliveryAddress: user.attributes?.customer_contact_info?.data?.attributes?.deliveryAddress,
        firstName: user.attributes?.customer_contact_info?.data?.attributes?.firstName,
        lastName: user.attributes?.customer_contact_info?.data?.attributes?.lastName,
        type: user.attributes?.role?.data?.attributes?.name,
        clientType: user.attributes?.role?.data?.attributes?.name,
        carModel: user.attributes?.customer_vehicles?.data.map(
          item =>
            item.attributes?.car?.data?.attributes?.car_model?.data?.attributes?.car_model_locales
              ?.data[0].attributes?.name || ''
        ),
      };
    });
    const pagination: PaginationClientsType = {
      page: data.usersPermissionsUsers.meta.pagination.page,
      pageCount: data.usersPermissionsUsers.meta.pagination.pageCount,
      pageSize: data.usersPermissionsUsers.meta.pagination.pageSize,
      total: data.usersPermissionsUsers.meta.pagination.total,
    };
    return { clients, pagination, isLoading: loading };
  }

  function getFilters(): UsersPermissionsUserFiltersInput | undefined {
    if (filters) {
      return {
        or: [
          { id: { containsi: filters?.searchNumbers } },
          { username: { containsi: filters?.searchStrings } },
          { role: { name: { containsi: filters?.searchStrings } } },
          {
            customer_contact_info: {
              or: [
                { phoneNumbers: { number: { containsi: filters?.searchNumbers } } },
                { firstName: { containsi: filters?.searchStrings } },
                { lastName: { containsi: filters?.searchStrings } },
              ],
            },
          },
        ],
      };
    }
  }
  return { clients: [], isLoading: loading };
};
