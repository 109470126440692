import { useMemo, useState } from 'react';
import {
  GetOrderItemsQuery,
  GetOrderItemsQueryVariables,
  useGetOrderItemsLazyQuery,
  useGetOrderItemsQuery,
} from '../__generated__/getOrderItems';
import { OrderItemType } from '../../../helpers/types';
import {
  Enum_Componentorderstatuseditinghistory_Status,
  Enum_Language_Type,
  OrderItemEntity,
  OrderItemFiltersInput,
} from '../../../__generated__/types';
import { useLocalization } from '../../../localization';
import { getPhone, handlerError } from '../../../helpers/functions';
import {
  GetOrderItemForReturnQueryVariables,
  useGetOrderItemForReturnQuery,
  GetOrderItemForReturnQuery,
} from '../__generated__/getOrderItemForReturn';
import { formatOrderNumber } from '../../../utils/formatOrderNumber';

interface ReturnHook {
  orderItems: Array<OrderItemType>;
  loading: boolean;
  refetch?: () => void;
  variablesQuery?: GetOrderItemsQueryVariables;
}
interface ReturnHookOrderItem extends Omit<ReturnHook, 'orderItems'> {
  orderItem: OrderItemType;
}
interface ReturnHookLazy extends ReturnHook {
  handlerRunQuery(): void;
}
export interface ReturnHookAwaiting extends ReturnHook {
  page: number;
  totalPage: number;
  pageSize: number;
  changePage(page: number): void;
}

const PAGE_COUNT_MAX = 4;
export function getVarOrderItems(selectLanguage?: Enum_Language_Type): GetOrderItemsQueryVariables {
  return {
    filters: {
      order: { status: { containsi: Enum_Componentorderstatuseditinghistory_Status.Awaiting } },
    },
    // pagination: { page: 1, pageSize: PAGE_COUNT_MAX },
    sort: ['id:desc'],
    filtersLocale: { locale: { eqi: selectLanguage } },
    pagination: { limit: -1 },
  };
}
export const useGetOrderItem = (eanNumber: string): ReturnHookOrderItem => {
  const { updateSelectLanguage } = useLocalization();
  const variables: GetOrderItemForReturnQueryVariables = {
    input: { stockBarcode: eanNumber },
    filtersLocale: { locale: { eqi: updateSelectLanguage } },
  };
  const { data, loading } = useGetOrderItemForReturnQuery({
    variables,
  });
  const orderItem = useMemo(() => {
    return getDataOderItems(data)[0];
  }, [data]);

  return {
    variablesQuery: variables,
    orderItem,
    loading,
  };
};

export const useGetOrderItems = (orderId: string, lang?: string): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const variables: GetOrderItemsQueryVariables = {
    filters: orderId ? { order: { id: { eq: orderId } } } : {},
    pagination: { limit: -1 },
    filtersLocale: { locale: { eqi: lang ?? updateSelectLanguage } },
    sort: ['id'],
  };
  const { data, loading, refetch } = useGetOrderItemsQuery({
    variables,
  });

  const orderItems = useMemo(() => {
    return getDataOderItems(data);
  }, [data]);

  return {
    variablesQuery: variables,
    orderItems,
    loading,
    refetch,
  };
};

export const useGetLazyOrderItems = (orderId: string): ReturnHookLazy => {
  const [orderItems, setOrderItems] = useState<Array<OrderItemType>>([]);
  const { updateSelectLanguage } = useLocalization();
  const variables: GetOrderItemsQueryVariables = {
    filters: { order: { id: { eq: orderId } } },
    pagination: { limit: -1 },
    filtersLocale: { locale: { eqi: updateSelectLanguage } },
    sort: ['id'],
  };
  const [runGetOrderItems, { loading }] = useGetOrderItemsLazyQuery({ variables });
  const handlerRunQuery = async () => {
    try {
      const response = await runGetOrderItems();
      if (response?.data?.orderItems?.data?.length) {
        const oi = getDataOderItems(response?.data);
        setOrderItems(oi);
      }
    } catch (error: unknown) {
      handlerError(error);
    }
  };
  return {
    variablesQuery: variables,
    loading,
    orderItems,
    handlerRunQuery,
  };
};
interface PropsOrderItemsAwaiting {
  filter?: OrderItemFiltersInput;
}
export const useGetOrderItemsAwaiting = ({
  filter,
}: PropsOrderItemsAwaiting): ReturnHookAwaiting => {
  const { updateSelectLanguage } = useLocalization();
  const [page, setPage] = useState(1);
  const { data, loading } = useGetOrderItemsQuery({
    variables: {
      filters: {
        order: { status: { containsi: Enum_Componentorderstatuseditinghistory_Status.Awaiting } },
        ...filter,
      },
      sort: ['id:desc'],
      filtersLocale: { locale: { eqi: updateSelectLanguage } },
      pagination: { limit: -1 },
    },
    fetchPolicy: 'cache-first',
  });
  const orderItems = useMemo(() => {
    return getDataOderItems(data);
  }, [data]);

  const changePage = (_page: number): void => {
    setPage(_page + 1);
  };

  return {
    orderItems,
    loading,
    page,
    changePage,
    pageSize: data?.orderItems?.meta.pagination.total || 0,
    totalPage: data?.orderItems?.meta.pagination.total
      ? Math.ceil(data?.orderItems?.meta.pagination.total / PAGE_COUNT_MAX)
      : 0,
  };
};

export function getDataOderItems(
  data?: GetOrderItemsQuery | GetOrderItemForReturnQuery
): Array<OrderItemType> {
  const orderItems: Array<OrderItemType> = [];
  if (data && 'orderItems' in data) {
    if (data.orderItems?.data.length) {
      data.orderItems?.data?.forEach(item => {
        orderItems.push(getOrderItemEntity(item as OrderItemEntity));
      });
    }
  } //
  else if (data && 'searchOrderItemForReturn' in data) {
    orderItems.push(getOrderItemEntity(data.searchOrderItemForReturn as OrderItemEntity));
  }
  return orderItems;
}
function getOrderItemEntity(item: OrderItemEntity): OrderItemType {
  let isConsole = false;
  const dataStore = localStorage.getItem('console');
  if (dataStore) {
    isConsole = !!JSON.parse(dataStore);
  }
  const orderNumberOrigin = item?.attributes?.order?.data?.attributes?.orderNumber ?? '';
  const orderNumberAdditional = item?.attributes?.order?.data?.attributes?.orderNumberAdd ?? '';

  const orderNumber = formatOrderNumber(orderNumberOrigin, orderNumberAdditional);

  if (isConsole) {
    // eslint-disable-next-line no-console
    console.log(
      item.attributes?.spare_archive?.data?.attributes?.tecdoc_articleNumber,
      item.attributes?.car_spare?.data?.attributes?.eanNumber,
      item.attributes?.barcode
    );
  }
  return {
    id: item?.id || '',
    carSpareId: item.attributes?.car_spare?.data?.id || '',
    productName:
      item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes?.title ||
      '',
    brandName:
      item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
        ?.brandName || '',
    deliveryDate: item.attributes?.delivery_date || '',
    providerPrice: item.attributes?.spare_archive?.data?.attributes?.supplierPrice || null,
    autoOrderLastError: item.attributes?.autoOrderLastError || '',
    provider:
      item.attributes?.spare_archive?.data?.attributes?.supplier?.data?.attributes?.name || '',
    customerPrice: item.attributes?.customer_price,
    createdAt: item.attributes?.createdAt || '',
    article: item.attributes?.spare_archive?.data?.attributes?.tecdoc_articleNumber || '',
    discount: item.attributes?.discount,
    quantity: item.attributes?.quantity?.orderedQuantity || 0,
    returnedQuantity: item.attributes?.quantity?.returnedQuantity || 0,
    quantityAfterRemovingAsReturn:
      (item.attributes?.quantity?.orderedQuantity || 0) -
      (item.attributes?.quantity?.returnedQuantity || 0),
    orderId: item?.attributes?.order?.data?.id || '',
    orderNumber,
    balanceQuantity: item.attributes?.quantity?.outgoingQuantity || 0,
    deliveryQuantity: item.attributes?.quantity?.deliveryQuantity || 0,
    outgoingQuantity: item.attributes?.quantity?.outgoingQuantity || 0,
    pickedUpQuantity: item.attributes?.quantity?.pickedUpQuantity || 0,
    confirmedQuantity: item.attributes?.quantity?.confirmedQuantity || 0,
    statusOrder: item.attributes?.order?.data?.attributes?.status,
    isOrdered: item.attributes?.isOrdered,
    isOrderConfirmed: item.attributes?.isOrderConfirmed,
    barcode: item.attributes?.barcode,
    eanNumber: item.attributes?.car_spare?.data?.attributes?.eanNumber,
    images: item.attributes?.car_spare?.data?.attributes?.images,
    deliveryAddress: item.attributes?.order?.data?.attributes?.deliveryAddress,
    firstName:
      item.attributes?.order?.data?.attributes?.customer?.data?.attributes?.customer_contact_info
        ?.data?.attributes?.firstName || '',
    lastName:
      item.attributes?.order?.data?.attributes?.customer?.data?.attributes?.customer_contact_info
        ?.data?.attributes?.firstName || '',
    customerPhone: getPhone(
      item.attributes?.order?.data?.attributes?.customer?.data?.attributes?.customer_contact_info
        ?.data?.attributes?.phoneNumbers
    ),
    customerId:
      item.attributes?.order?.data?.attributes?.customer?.data?.attributes?.customer_contact_info
        ?.data?.id || '',
    phoneNumber: item.attributes?.order?.data?.attributes?.phoneNumber || '',
    lockForReturn: item.attributes?.stock?.data?.attributes?.lockForReturn || undefined,
    comment: item.attributes?.comment || '',
    articleCriteria:
      item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
        ?.articleCriteria,
  };
}
