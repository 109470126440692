/* eslint-disable no-console */
import React, { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../../../components/TableProduct/TableProduct';
import { useLocalization } from '../../../../localization';
import { CustomModal } from '../../../../components';
import { OrderItemType } from '../../../../helpers/types';
import { ToolTypeComment } from '../../../../components/ToolTypeComment';

interface Props {
  orderItems: Array<OrderItemType>;
  loading: boolean;
  totalPage?: number;
  currentPage?: number;
  changePage?: (page: number) => void;
}

export const ExpectedTabTable: FC<Props> = ({ orderItems, loading }) => {
  const { translateLang } = useLocalization();

  const [modalMessage, setModalMessage] = useState<{ isOpen: boolean; message: string }>({
    isOpen: false,
    message: '',
  });

  const formatDate = (item: string | Date) => {
    if (typeof item === 'string') {
      const str = item.split('-');
      return `${str[2]}.${str[1]}.${str[0]}`;
    }
    const date = new Date(item);
    let day = date.getDay().toString();
    if (day.length === 1) {
      day = '0' + day;
    }
    let month = (date.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    const year = date.getFullYear();
    // return date.toLocaleDateString('en-US', {
    //   year: 'numeric',
    //   month: '2-digit',
    //   day: '2-digit',
    // });
    return `${day}.${month}.${year}`;
  };
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: { width: '70px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '180px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '180px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('provider'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('orders'),
        containerProps: { width: '100px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('dateReceipt'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('quantity'),
        containerProps: { color: '#f1f3f8', align: 'left' },
      },
      {
        title: translateLang('productComment'),
        containerProps: { width: '260px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };
  const getRowsTable = (): Array<TableCellsValue> => {
    if (orderItems && orderItems?.length) {
      return orderItems?.map(item => {
        const values: Array<BoxCell> = [];
        values.push({
          val: item.id,
          containerProps: { width: '20px', height: '20px', borderLeft: 'none' },
        });
        values.push({
          val: item?.article || '',
        });
        values.push({ val: item?.productName });
        values.push({ val: item?.provider });

        values.push({ val: '#' + item?.orderNumber });
        values.push({ val: formatDate(item.deliveryDate as string) });

        values.push({
          val: `${item.balanceQuantity} / ${item.quantity}`,
          containerProps: {
            justifyContent: 'flex-start',
            paddingLeft: '30px!important',
            color: item.balanceQuantity === item.quantity ? '#97A408' : '#F44336',
            borderRight: 'none',
          },
        });
        values.push({ val: <ToolTypeComment comment={item?.comment} width="200px" /> });
        return { cellsValues: values };
      });
    }
    return [];
  };
  return (
    <>
      <Box boxSizing="border-box" width="100%">
        <TableProduct
          itemsRows={getRowsTable()}
          itemsHeader={getHeadersTable()}
          loading={loading}
        />
      </Box>
      {modalMessage.isOpen && (
        <CustomModal
          title={translateLang('message')}
          handleClose={() => setModalMessage({ isOpen: false, message: '' })}
          open={true}
        >
          <Typography>{modalMessage.message}</Typography>
        </CustomModal>
      )}
    </>
  );
};
