import React, { CSSProperties, forwardRef, PropsWithChildren } from 'react';
import { useBarcode } from 'next-barcode';
import { formatDate } from '../../../helpers/functions';

const styleText: CSSProperties = {
  display: 'block',
  fontSize: '12px',
  textAlign: 'left',
};
interface Props {
  barcode?: string | null;
  orderNumber?: string | null;
  orderDate?: string | null;
  customerPhoneNumber?: string | null;
  titleProduct?: string | null;
  articleNumber?: string | null;
}
export const PrintSticker = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ barcode, orderNumber, orderDate, customerPhoneNumber, titleProduct, articleNumber }, ref) => {
    const getOrderData = () => {
      let date = 'kein Datum';
      if (orderDate) {
        const formattedDate = formatDate(orderDate);
        date = formattedDate.split('/').join('-');
      }
      return (
        <span style={{ ...styleText }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {`Bestell nummer: ${orderNumber || ''}; ${date}`}
        </span>
      );
    };
    const getPhoneUser = () => {
      return (
        <span style={{ ...styleText }}>
          &nbsp;&nbsp;&nbsp;&nbsp;Tel.: {customerPhoneNumber || '-'}
        </span>
      );
    };

    const { inputRef } = useBarcode({
      value: barcode || 'no number',
      options: {
        background: 'transparent',
        textPosition: 'bottom',
        textAlign: 'left',
        textMargin: 1,
        height: 50,
        fontSize: 16,
        margin: 0,
      },
    });

    return (
      <div
        ref={ref}
        style={{
          display: 'none',
          boxSizing: 'border-box',
          position: 'absolute',
          left: 0,
          top: 0,
          backgroundColor: 'white',
          paddingLeft: '20px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: '16px',
            top: '20px',
            width: '106px',
            height: '16px',
          }}
        >
          <svg viewBox="0 0 211 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.50171 29.3553L15.193 1.73987H20.2461L33.0158 29.3553H26.8659L23.5364 21.6778L25.9258 23.3621H9.51328L11.9419 21.6778L8.61235 29.3553H2.50171ZM17.6608 8.12471L12.6078 20.1893L11.511 18.6225H23.8498L22.988 20.1893L17.8175 8.12471H17.6608Z"
              fill="#232323"
            />
            <path
              d="M47.3525 29.747C43.2787 29.747 40.2495 28.7469 38.2649 26.7518C36.2802 24.7541 35.2879 21.7719 35.2879 17.8V1.73993H41.3985V17.8C41.3985 20.0457 41.8686 21.7431 42.8087 22.8922C43.7488 24.0412 45.2634 24.6157 47.3525 24.6157C49.311 24.6157 50.7812 24.0412 51.7605 22.8922C52.7398 21.7431 53.2307 20.0457 53.2307 17.8V1.73993H59.2238V17.8C59.2238 21.7693 58.2367 24.7541 56.2651 26.7518C54.2909 28.7469 51.3218 29.747 47.3525 29.747Z"
              fill="#232323"
            />
            <path
              d="M71.0508 29.3553V6.71462H62.3157V1.73993H85.9749V6.71462H77.2006V29.3553H71.0508Z"
              fill="#232323"
            />
            <path
              d="M126.945 29.3423V1.72687H139.675C142.704 1.72687 145.042 2.36144 146.687 3.62535C148.332 4.89187 149.155 6.63627 149.155 8.85334C149.155 10.5246 148.664 11.9348 147.684 13.0838C146.705 14.2328 145.365 15.0032 143.668 15.3949V14.8073C145.653 15.0946 147.186 15.8257 148.272 17.0009C149.356 18.176 149.896 19.7036 149.896 21.5838C149.896 24.0124 149.027 25.9135 147.293 27.2845C145.556 28.6555 143.159 29.3423 140.104 29.3423H126.945ZM132.781 13.0472H138.578C140.171 13.0472 141.352 12.7678 142.122 12.2037C142.892 11.6423 143.279 10.8014 143.279 9.67854C143.279 8.52953 142.892 7.67561 142.122 7.11155C141.352 6.5501 140.169 6.26807 138.578 6.26807H132.781V13.0472ZM132.781 24.7593H139.362C140.877 24.7593 142.039 24.4851 142.848 23.9367C143.658 23.3883 144.062 22.4874 144.062 21.2339C144.062 19.9282 143.658 18.996 142.848 18.4319C142.039 17.8705 140.877 17.591 139.362 17.591H132.781V24.7593Z"
              fill="#F44336"
            />
            <path
              d="M154.208 29.3423V1.72687H166.742C169.85 1.72687 172.244 2.46589 173.931 3.94132C175.616 5.41676 176.457 7.47453 176.457 10.112C176.457 11.8355 176.078 13.3188 175.321 14.5566C174.563 15.797 173.467 16.7502 172.03 17.4161C170.594 18.082 168.897 18.4162 166.938 18.4162L167.369 17.5545H169.014C170.137 17.5545 171.142 17.8548 172.03 18.4554C172.918 19.056 173.636 19.9178 174.185 21.0407L178.298 29.3449H171.56L167.291 20.4531C166.977 19.7219 166.489 19.2336 165.82 18.9829C165.154 18.7348 164.392 18.6121 163.528 18.6121H160.316V29.3449H154.208V29.3423ZM160.318 14.144H165.685C167.278 14.144 168.492 13.8176 169.327 13.1647C170.163 12.5119 170.581 11.5457 170.581 10.2661C170.581 8.98652 170.163 8.03337 169.327 7.40663C168.492 6.7799 167.278 6.46653 165.685 6.46653H160.318V14.144Z"
              fill="#F44336"
            />
            <path
              d="M181.118 15.515C181.118 12.6425 181.666 10.1434 182.763 8.01508C183.86 5.8868 185.414 4.24163 187.425 3.07956C189.435 1.9175 191.851 1.33777 194.671 1.33777C197.465 1.33777 199.873 1.92011 201.897 3.07956C203.921 4.24163 205.493 5.8868 206.618 8.01508C207.741 10.1434 208.303 12.632 208.303 15.4758C208.303 18.3483 207.746 20.8553 206.636 22.9966C205.527 25.1379 203.952 26.7962 201.915 27.9713C199.878 29.1464 197.463 29.734 194.669 29.734C191.874 29.734 189.464 29.1464 187.443 27.9713C185.419 26.7962 183.86 25.1379 182.763 22.9966C181.666 20.8579 181.118 18.364 181.118 15.515ZM187.5 15.515C187.5 18.3614 188.101 20.5863 189.302 22.1949C190.503 23.8009 192.292 24.6026 194.669 24.6026C197.019 24.6026 198.808 23.8009 200.035 22.1949C201.262 20.5889 201.876 18.364 201.876 15.515C201.876 12.6425 201.267 10.4176 200.053 8.83767C198.839 7.25778 197.042 6.46653 194.666 6.46653C192.29 6.46653 190.501 7.25778 189.3 8.83767C188.101 10.4176 187.5 12.6425 187.5 15.515Z"
              fill="#F44336"
            />
            <path
              d="M105.683 1.61975C96.3994 1.61975 88.876 9.14576 88.876 18.4266C88.876 22.5422 90.3592 26.313 92.8166 29.2352L94.8926 27.1591L97.8226 24.2213C97.2716 23.4744 96.8224 22.644 96.5012 21.7535L93.3911 24.8715C92.3778 22.9443 91.8033 20.7534 91.8033 18.4266C91.8033 11.2114 97.3081 5.28352 104.348 4.60718V8.75145C104.787 8.69138 105.234 8.65744 105.688 8.65744C106.142 8.65744 106.589 8.69138 107.028 8.75145V4.60979C114.068 5.28352 119.573 11.2114 119.573 18.4292C119.573 20.7482 119.001 22.9339 117.993 24.8559L114.878 21.7431C114.556 22.6336 114.11 23.464 113.559 24.2135L116.494 27.1434L118.57 29.2195C121.02 26.3 122.495 22.537 122.495 18.4266C122.49 9.14576 114.964 1.61975 105.683 1.61975Z"
              fill="#232323"
            />
            <path
              d="M114.802 21.9155C114.825 21.8581 114.851 21.8006 114.872 21.7405L117.988 24.8533C118.996 22.9313 119.567 20.7456 119.567 18.4267C119.567 11.2114 114.063 5.28359 107.022 4.60724V8.75151C111.783 9.40436 115.449 13.486 115.449 18.4241C115.449 19.6567 115.217 20.8318 114.802 21.9155Z"
              fill="#F44336"
            />
            <path
              d="M106.413 8.68873C106.382 8.68614 106.351 8.68356 106.32 8.68097C106.351 8.68356 106.382 8.68873 106.413 8.68873Z"
              fill="#F44336"
            />
            <path
              d="M114.669 22.2471C114.627 22.3438 114.585 22.443 114.538 22.5396C114.585 22.4404 114.627 22.3438 114.669 22.2471Z"
              fill="#F44336"
            />
            <path
              d="M114.483 10.5873L113.535 9.6394L106.97 16.2044C106.592 15.9851 106.15 15.8545 105.68 15.8545C104.26 15.8545 103.108 17.0061 103.108 18.4267C103.108 19.8473 104.26 20.9989 105.68 20.9989C107.101 20.9989 108.252 19.8473 108.252 18.4267C108.252 17.9619 108.127 17.5284 107.913 17.155L114.483 10.5873Z"
              fill="#232323"
            />
          </svg>
        </div>
        <span style={{ ...styleText }}>&nbsp;&nbsp;&nbsp;&nbsp;{titleProduct}</span>
        <span style={{ ...styleText }}>&nbsp;&nbsp;&nbsp;&nbsp;Artikel: {articleNumber}</span>
        {getOrderData()}
        {getPhoneUser()}
        <div style={{ display: 'flex' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <svg ref={inputRef} />
        </div>
      </div>
    );
  }
);
PrintSticker.displayName = 'PrintSticker';
