import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { addDays, endOfToday, endOfDay, format, startOfDay, startOfMonth, subDays } from 'date-fns';
import { Autocomplete, Box, Button, Stack, TextField, UseAutocompleteProps } from '@mui/material';

import { useLocalization } from '../../../../localization';
import { useDefaultRangeCalendar } from '../../../../hooks';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import {
  CustomerReturnFiltersInput,
  Enum_Customerreturn_Status,
} from '../../../../__generated__/types';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useSearchParams } from 'react-router-dom';
interface Props {
  result?: string | number;
  setFilterSubscription: Dispatch<SetStateAction<CustomerReturnFiltersInput>>;
  changePage: (_page: number) => void;
}

export type NoSpareStatusType = {
  label: string;
  value: string;
};

export type ReturnStatusEditingHistory = {
  label: Enum_Customerreturn_Status;
  value: string;
};

export const ToolBarReturnsTab: FC<Props> = ({ result, setFilterSubscription, changePage }) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { changeDate, selDateTo, selDateFrom, resetFilters } = useFiltersSearchParamsForOrders({
    dateFrom: addDays(new Date(), -30).toISOString(),
    dateTo: new Date().toISOString(),
  });
  const [selectDates, setSelectedDates] = useState(getDatesFilters);
  const [currentValueStatus, setCurrentValueStatus] = useState<ReturnStatusEditingHistory | null>(
    null
  );
  const WareHouseReturnStatus: ReturnStatusEditingHistory[] = [
    ...[Enum_Customerreturn_Status.Created, Enum_Customerreturn_Status.Completed].map(value => ({
      label: value,
      value: translateLang(value),
    })),
  ];
  const handlerReset = () => {
    changePage(0);
    resetFilters();
    setCurrentValueStatus(null);
    setFilterSubscription(oldFilter => {
      const reset = { ...oldFilter };
      delete reset.status;
      return reset;
    });
    changeDate(addDays(new Date(), -30).toISOString(), new Date().toISOString());
    setSelectedDates([addDays(new Date(), -30), new Date()]);
    setSearchParams(prev => {
      const params = new URLSearchParams(prev);
      params.delete('dateFrom');
      params.delete('dateTo');
      params.delete('page');
      params.delete('status');
      return params;
    });
  };
  const handlerChangesDates = (val: Date[]) => {
    changePage(0);
    setSelectedDates(val);
    changeDate(
      val[0]?.toISOString(),
      val[1] ? addDays(val[1], 1)?.toISOString() : addDays(val[0], 1).toISOString()
    );
  };

  const handlerClearFilter = () => {
    changePage(0);
    setCurrentValueStatus(null);
    setFilterSubscription(oldFilter => {
      const reset = { ...oldFilter };
      delete reset.status;
      return reset;
    });
    setSearchParams(prev => {
      const params = new URLSearchParams(prev);
      params.delete('status');
      return params;
    });
  };
  const handleChangeStatus: UseAutocompleteProps<
    NoSpareStatusType,
    undefined,
    undefined,
    undefined
  >['onChange'] = (evt, option): void => {
    if (option) {
      setSearchParams(prev => {
        const params = new URLSearchParams(prev);
        params.set('status', option.label);
        return params;
      });
      setCurrentValueStatus(option as ReturnStatusEditingHistory);
      setFilterSubscription(oldFilter => ({
        ...oldFilter,
        status: { containsi: option.value },
      }));
    }
  };

  useEffect(() => {
    const status = searchParams.get('status');
    if (
      status &&
      Object.values(Enum_Customerreturn_Status).includes(status as Enum_Customerreturn_Status)
    ) {
      const matchingOption = WareHouseReturnStatus.find(
        option => option.label === (status as Enum_Customerreturn_Status)
      );
      setCurrentValueStatus(matchingOption || null);

      setFilterSubscription(oldFilter => ({
        ...oldFilter,
        status: { containsi: status },
      }));
    } else {
      setCurrentValueStatus(null);

      setFilterSubscription(oldFilter => {
        const updatedFilter = { ...oldFilter };
        delete updatedFilter.status;
        return updatedFilter;
      });
    }
  }, []);

  useEffect(() => {
    setFilterSubscription(oldFilter => ({
      ...oldFilter,
      dateReturned: {
        between: [
          selDateFrom !== ''
            ? format(startOfDay(new Date(selDateFrom)), 'yyyy-MM-dd')
            : format(startOfDay(subDays(new Date(), 30)), 'yyyy-MM-dd'),
          selDateTo !== ''
            ? format(endOfDay(new Date(selDateTo)), 'yyyy-MM-dd')
            : format(endOfDay(new Date()), 'yyyy-MM-dd'),
        ],
      },
    }));
  }, [selDateFrom, selDateTo, selectDates, setFilterSubscription]);

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [startOfDay(new Date(selDateFrom)), endOfDay(new Date(selDateTo))];
    }
    return [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())];
  }
  return (
    <WrapperToolBar>
      <Stack width="100%" flexDirection="row" justifyContent="left">
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || ''}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <Stack direction="row" gap={2} alignItems="center">
          <TranslatedField originText={translateLang('status')} />
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ minWidth: 300 }}
            value={currentValueStatus}
            options={WareHouseReturnStatus || []}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderInput={params => <TextField {...params} label={translateLang('status')} />}
            getOptionLabel={option => {
              return option?.value || '';
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.label}>
                {option.value}
              </Box>
            )}
            onChange={handleChangeStatus}
            onInputChange={(e, value, reason) => {
              if (reason === 'clear') {
                handlerClearFilter();
              }
            }}
          />
        </Stack>

        <Stack spacing={2} bgcolor="white" flexDirection="row" justifyContent="right">
          <Stack direction="row" gap={2} alignItems="center">
            <RangeCalendar
              selectedDates={selectDates}
              defaultRangeDates={reportRangeDates}
              setSelectedDates={handlerChangesDates}
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={handlerReset}
              style={{
                width: '100%',
                textTransform: 'none',
              }}
            >
              <TranslatedField
                noWrap
                isTranslate
                fontSize={16}
                color="black"
                originText="resetFilters"
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </WrapperToolBar>
  );
};
