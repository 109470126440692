import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type UpdateCustomerReturnStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  status: Types.Enum_Customerreturn_Status;
}>;


export type UpdateCustomerReturnStatusMutation = { __typename?: 'Mutation', updateCustomerReturn?: { __typename?: 'CustomerReturnEntityResponse', data?: { __typename?: 'CustomerReturnEntity', id?: string | null, attributes?: { __typename?: 'CustomerReturn', status: Types.Enum_Customerreturn_Status } | null } | null } | null };


export const UpdateCustomerReturnStatusDocument = gql`
    mutation updateCustomerReturnStatus($id: ID!, $status: ENUM_CUSTOMERRETURN_STATUS!) {
  updateCustomerReturn(id: $id, data: {status: $status}) {
    data {
      id
      attributes {
        status
      }
    }
  }
}
    `;
export type UpdateCustomerReturnStatusMutationFn = Apollo.MutationFunction<UpdateCustomerReturnStatusMutation, UpdateCustomerReturnStatusMutationVariables>;

/**
 * __useUpdateCustomerReturnStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerReturnStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerReturnStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerReturnStatusMutation, { data, loading, error }] = useUpdateCustomerReturnStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateCustomerReturnStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerReturnStatusMutation, UpdateCustomerReturnStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerReturnStatusMutation, UpdateCustomerReturnStatusMutationVariables>(UpdateCustomerReturnStatusDocument, options);
      }
export type UpdateCustomerReturnStatusMutationHookResult = ReturnType<typeof useUpdateCustomerReturnStatusMutation>;
export type UpdateCustomerReturnStatusMutationResult = Apollo.MutationResult<UpdateCustomerReturnStatusMutation>;
export type UpdateCustomerReturnStatusMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerReturnStatusMutation, UpdateCustomerReturnStatusMutationVariables>;