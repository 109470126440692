import React, { FC } from 'react';
import * as yup from 'yup';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { Icon } from '../../../../legos';
import { theme } from '../../../../helpers';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useCreateSupplierMutation } from '../../../../graphql/mutations/__generated__/createSupplier';
import { useFormik } from 'formik';
import { useLocalization } from '../../../../localization';
import { WrapperField } from '../../../Authorization/components/WrapperField';
import { CustomHelperText } from '../../../Authorization/components/CustomHelperText';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';

type CreateNewSupplierProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  refetchSuppliers: () => void;
};

export const CreateNewSupplier: FC<CreateNewSupplierProps> = ({
  isModalOpen,
  handleClose,
  refetchSuppliers,
}) => {
  const { addNotification } = useHandlerNotificationApp();
  const { translateLang } = useLocalization();
  const [runCreateSupplier, { loading }] = useCreateSupplierMutation();

  const validationSchema = yup.object({
    name: yup.string().required(translateLang('emptyInputError')),
    deliveryDay: yup.number().moreThan(-1, translateLang('validationErrorNumberMoreThan-1')),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      deliveryDay: 1,
    },
    validationSchema,
    onSubmit: async ({ deliveryDay, name }) => {
      try {
        await runCreateSupplier({
          variables: {
            data: {
              name,
              deliveryDay,
            },
          },
        });
        addNotification({
          messageError: translateLang('providerCreated'),
          typeMessage: 'success',
        });
      } catch (error: any) {
        addNotification({
          messageError: error.message,
          typeMessage: 'error',
        });
      } finally {
        refetchSuppliers();
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      disableEscapeKeyDown
      open={isModalOpen}
      closeAfterTransition
      maxWidth="lg"
      fullWidth
      disableScrollLock
    >
      <DialogTitle>
        <Stack alignItems="center">
          <Stack width="100%" flexDirection="row" justifyContent="end" mb={2}>
            <IconButton onClick={() => handleClose()} sx={{ p: 0 }}>
              <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Stack>
          <TranslatedField
            isTranslate
            variant="h4"
            color={theme.palette.common.darkBlue}
            marginBottom={2.5}
            fontSize={30}
            originText="newSupplier"
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <WrapperField title={translateLang('supplierName')}>
          <TextField
            color="success"
            fullWidth
            name="name"
            variant="outlined"
            InputProps={{
              sx: {
                borderRadius: '10px',
                [theme.breakpoints.up('md')]: {
                  width: '40%',
                },
              },
              value: values.name,
              onChange: handleChange,
            }}
          />
          <CustomHelperText error={!!errors.name && touched.name} helperText={errors.name} />
        </WrapperField>
        <WrapperField title={translateLang('deliveryDays')}>
          <TextField
            color="success"
            fullWidth
            name="deliveryDay"
            variant="outlined"
            InputProps={{
              sx: {
                borderRadius: '10px',
                [theme.breakpoints.up('md')]: {
                  width: '25%',
                },
              },
              value: values.deliveryDay,
              onChange: handleChange,
            }}
          />
          <CustomHelperText
            error={!!errors.deliveryDay && touched.deliveryDay}
            helperText={errors.deliveryDay}
          />
        </WrapperField>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around', pb: 4 }}>
        <Button
          onClick={() => handleSubmit()}
          variant="contained"
          sx={{ height: 50, minWidth: 150, textTransform: 'none' }}
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size="2.5rem" sx={{ position: 'absolute' }} /> : null}
          <TranslatedField originText="createSupplier" fontSize={16} isTranslate noWrap />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
