import React, { FC, useRef, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';

import { theme } from '../../../../../../helpers';
import { TransactionType, useGetTransactions } from './hooks/useGetTransactions';
import { PAGE_COUNT } from '../../../../../../helpers/constants';
import { useLocalization } from '../../../../../../localization';
import { ClientTransactionTable } from './ClientTransactionTable';
import { CustomerPaymentModal } from '../../../CustomerPaymentModal';
import { ClientTransactionToolbar } from './ClientTransactionToolbar';
import { CustomerAccountTransactionFiltersInput } from '../../../../../../__generated__/types';
import { useFiltersSearchParamsForOrders } from '../../../../../../hooks/useFiltersSearchParamsForOrders';
import { useFiltersSearchParamsForClients } from '../../../../../../hooks/useFiltersSearchParamsForClients';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useDeleteCustomerAccountTransactionMutation } from '../../../../../../graphql/mutations/__generated__/deleteCustomerAccountTransaction';
import { ModalConfirm } from '../../../../../../components/ModalConfirm';
import { EditCustomerTransactionModal } from './EditCustomerTransactionModal';
import { GetOrderByIdQuery } from '../../../../../../graphql/queries/__generated__/getOrderById';
import { GetCustomerBalanceDocument } from '../../../../../../graphql/queries/__generated__/getCustomerBalance';
import { GetCustomerAccountsDocument } from '../../../../../../graphql/queries/__generated__/getCustomerAccounts';
import { formatDate } from '../../../../../../helpers/functions';

interface ClientTransactionProps {
  userId: string;
}

export const ClientTransaction: FC<ClientTransactionProps> = ({ userId }) => {
  const transactionRef = useRef<TransactionType | null>(null);
  const orderRef = useRef<GetOrderByIdQuery | null>(null);
  const { translateLang } = useLocalization();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [editCustomerTransactionModal, setEditCustomerTransactionModal] = useState(false);

  const [transactionModal, setTransactionModal] = useState(false);

  const { selStatus, selDateTo, selDateFrom } = useFiltersSearchParamsForOrders();

  const { page, changePage } = useFiltersSearchParamsForClients();
  const { transactions, pagination, isLoading, customerPaymentsRefetch } = useGetTransactions({
    pagination: {
      page,
      pageSize: PAGE_COUNT,
    },
    filters: getFiltersOrders(),
    customerId: userId,
  });

  const [runDeleteCustomerAccountTransaction] = useDeleteCustomerAccountTransactionMutation({
    refetchQueries: [
      {
        query: GetCustomerBalanceDocument,
        variables: {
          input: {
            customerId: userId,
          },
        },
      },
      {
        query: GetCustomerAccountsDocument,
        variables: {
          pagination: {},
          sort: [],
          filters: { customerId: { eq: userId } },
        },
      },
    ],
  });

  const changePageNumber = (value: number) => {
    changePage(value + 1);
  };

  const toggleTransactionModal = () => {
    setTransactionModal(!transactionModal);
  };

  function getFiltersOrders(): CustomerAccountTransactionFiltersInput | undefined {
    const filters: CustomerAccountTransactionFiltersInput | undefined = {};

    if (selStatus) {
      filters['documentType'] = {
        eq: !Array.isArray(selStatus) ? selStatus : '',
      };
    }

    if (selDateTo && selDateFrom) {
      filters['createdAt'] = {
        between: [selDateFrom, selDateTo],
      };
    }
    return Object.keys(filters).length > 0 ? filters : undefined;
  }

  const handleAction = (
    transaction: TransactionType,
    type: 'edit' | 'open' | 'delete',
    order?: GetOrderByIdQuery
  ) => {
    transactionRef.current = transaction;
    orderRef.current = order ?? null;
    if (type === 'delete') {
      setConfirmDeleteModal(true);
    } else if (type === 'edit') {
      setEditCustomerTransactionModal(true);
    }
  };

  const closeConfirmDeleteTransaction = () => {
    setConfirmDeleteModal(false);
    transactionRef.current = null;
  };

  const closeCustomerPaymentModal = () => {
    setEditCustomerTransactionModal(false);
    transactionRef.current = null;
  };

  const handleDeleteTransaction = async () => {
    if (transactionRef.current)
      await runDeleteCustomerAccountTransaction({
        variables: {
          id: transactionRef.current.id,
        },
      }).finally(() => {
        closeConfirmDeleteTransaction();
      });
    customerPaymentsRefetch();
  };

  return (
    <Box width="100%">
      <ClientTransactionToolbar />
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pt={2.5}
        pl={3.75}
        pr={2}
        pb={2}
        bgcolor={theme.palette.common.white}
        sx={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      >
        <TranslatedField
          isTranslate
          variant="h5"
          fontSize={26}
          color="black"
          originText={translateLang('transactions')}
        />
        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{
            height: 50,
            p: 2,
            textTransform: 'none',
            backgroundColor: '#5269A3',
            borderRadius: '10px',
          }}
          onClick={toggleTransactionModal}
        >
          <TranslatedField originText={'createTransaction'} fontSize={16} isTranslate noWrap />
        </Button>
      </Stack>

      <ClientTransactionTable
        transactions={transactions}
        totalPage={pagination?.pageCount}
        changePage={changePageNumber}
        currentPage={page}
        loading={isLoading}
        action={handleAction}
      />
      {transactionModal ? (
        <CustomerPaymentModal
          isModalOpen={transactionModal}
          handleClose={toggleTransactionModal}
          userId={userId}
          customerPaymentsRefetch={customerPaymentsRefetch}
        />
      ) : null}
      <ModalConfirm
        closeModal={closeConfirmDeleteTransaction}
        title={translateLang('deleteTransaction')}
        information={
          <>
            <TranslatedField
              originText={'documentNumber'}
              isTranslate
              noWrap
              suffix={`: ${transactionRef.current?.id ?? ''}`}
            />
            <TranslatedField
              originText={'date'}
              isTranslate
              noWrap
              suffix={`: ${formatDate(transactionRef.current?.paymentDate)}`}
            />
          </>
        }
        openModal={confirmDeleteModal}
        handlerConfirm={handleDeleteTransaction}
      />
      <EditCustomerTransactionModal
        transaction={transactionRef.current}
        isModalOpen={editCustomerTransactionModal}
        handleClose={closeCustomerPaymentModal}
        order={orderRef.current?.order}
        userId={userId}
        refetch={customerPaymentsRefetch}
      />
    </Box>
  );
};
