import React, { FC, useEffect, useState } from 'react';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { Autocomplete, Box, Stack, TextField, UseAutocompleteProps } from '@mui/material';
import { useLocalization } from '../../../localization';

export type MultipleItemSelectFilterType = {
  label: string;
  value: string;
};
interface Props {
  selectedItems: string | string[];
  labelFilter: string;
  items?: Array<MultipleItemSelectFilterType>;
  changeUrlParams(selectedValues: string[]): void;
}
export const MultipleSelectFilter: FC<Props> = ({
  items = [],
  selectedItems,
  changeUrlParams,
  labelFilter,
}) => {
  const { translateLang } = useLocalization();
  const [keyResetFilter, setKeyResetFilter] = useState(Math.random().toString().slice(1, -1));
  const handleChangeFilter: UseAutocompleteProps<
    MultipleItemSelectFilterType,
    true,
    undefined,
    undefined
  >['onChange'] = (_, selectedOptions): void => {
    const selectedValues = selectedOptions.map(option => option.label);
    changeUrlParams(selectedValues);
  };

  const getDefaultValues = () => {
    if (selectedItems.length > 0) {
      return items.filter(item => selectedItems.includes(item.label));
    }
    return [];
  };

  useEffect(() => {
    if (selectedItems.length === 0) {
      setKeyResetFilter(Math.random().toString().slice(1, -1));
    }
  }, [selectedItems]);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TranslatedField originText={translateLang(labelFilter)} />
      <Autocomplete
        multiple
        key={keyResetFilter}
        size="small"
        disablePortal
        id="combo-box-demo"
        sx={{ minWidth: 200, maxWidth: 250 }}
        options={items}
        value={getDefaultValues()}
        renderInput={params => <TextField {...params} label={translateLang(labelFilter)} />}
        getOptionLabel={option => {
          return translateLang(option?.value) || '';
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.label}>
            {translateLang(option.value)}
          </Box>
        )}
        onChange={handleChangeFilter}
        onInputChange={(e, value, reason) => {
          if (reason === 'clear') {
            changeUrlParams([]);
          }
        }}
      />
    </Stack>
  );
};
