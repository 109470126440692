import React, { FC, useState } from 'react';
import { Autocomplete, Box, IconButton, Stack, TextField } from '@mui/material';
import { TableProductActive } from '../../../../../components/TableProductActive/TableProductActive';
import { useLocalization } from '../../../../../localization';
import { formatDate } from '../../../../../helpers/functions';
import { ReturnType } from '../hooks/useGetCustomerReturnsForWarehouseQuery';
import { ReturnStatusEditingHistory } from '../ToolBarReturnsTab';
import { Enum_Customerreturn_Status } from '../../../../../__generated__/types';
import { useUpdateCustomerReturnStatusMutation } from '../../../../../graphql/queries/__generated__/updateCustomerReturnStatus';
import { Edit, Visibility } from '@mui/icons-material';
import { CreateUpdateReturnModal } from '../CreateUpdateReturnModal';
import { getUserFullName } from '../../../../../utils/getUserFullName';

interface Props {
  itemRow?: ReturnType;
  returnedItems: ReturnType[];
}
export const TableReturnedItemsRow: FC<Props> = ({ itemRow, returnedItems }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const { translateLang } = useLocalization();

  const handleClose = () => {
    setOpen(false);
  };
  const [currentValueStatus, setCurrentValueStatus] = useState<ReturnStatusEditingHistory | null>(
    null
  );
  const [updateCustomerReturnStatus] = useUpdateCustomerReturnStatusMutation();

  const handleUpdateStatus = async (id: string, status: Enum_Customerreturn_Status) => {
    try {
      const response = await updateCustomerReturnStatus({
        variables: {
          id,
          status,
        },
      });
    } catch (err) {
      console.error('Error updating status:', err);
    }
  };
  const WareHouseReturnStatus: ReturnStatusEditingHistory[] = [
    ...[Enum_Customerreturn_Status.Created, Enum_Customerreturn_Status.Completed].map(value => ({
      label: value,
      value: translateLang(value),
    })),
  ];
  return (
    <>
      <TableProductActive.RowTable>
        <TableProductActive.CellTable ceilStyle={{ width: '20px', borderLeft: 'none' }}>
          {itemRow?.returnId}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {getUserFullName(itemRow?.data)}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {formatDate(itemRow?.dateReturned ?? '')}{' '}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {formatDate(itemRow?.correctiveInvoiceDate ?? '')}{' '}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {itemRow?.correctiveInvoiceNumber}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.total} €</TableProductActive.CellTable>
        <TableProductActive.CellTable>
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ minWidth: 180 }}
            defaultValue={
              WareHouseReturnStatus.find(option => option.label === itemRow?.status) || null
            }
            value={
              currentValueStatus ||
              WareHouseReturnStatus.find(option => option.label === itemRow?.status) ||
              null
            }
            disabled={
              (currentValueStatus?.label || itemRow?.status) ===
              Enum_Customerreturn_Status.Completed
            }
            options={WareHouseReturnStatus || []}
            renderInput={params => <TextField {...params} />}
            getOptionLabel={option => {
              return option?.value || '';
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.label}>
                {option.value}
              </Box>
            )}
            onChange={(event, selectedOption) => {
              if (selectedOption) {
                handleUpdateStatus(itemRow?.id ?? '', selectedOption?.label);
                setCurrentValueStatus(selectedOption);
              }
            }}
          />
        </TableProductActive.CellTable>

        <TableProductActive.CellTable
          ceilStyle={{ borderLeft: 'none', alignItems: 'end', gap: '10px' }}
        >
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <IconButton sx={{ bgcolor: '#F1F3F8' }}>
              <Visibility />
            </IconButton>
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <IconButton onClick={() => setOpen(true)} sx={{ bgcolor: '#F1F3F8' }}>
              <Edit />
            </IconButton>
          </Stack>
        </TableProductActive.CellTable>
      </TableProductActive.RowTable>
      <CreateUpdateReturnModal open={isOpen} close={handleClose} isEdit={true} item={itemRow} />
    </>
  );
};
