import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';
import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { TableReturnedItemsRow } from '../tablesComponents/TableReturnedItemsRow';
import { ReturnType } from './useGetCustomerReturnsForWarehouseQuery';

interface HookProps {
  returnedItems?: ReturnType[];
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetReturnedItemsTable = ({ returnedItems }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('id'),
        containerProps: { width: '220px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('clientShortCred'),
        containerProps: { width: '220px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('returnDate'),
        containerProps: { width: '115px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('correctiveInvoiceDate'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('correctiveInvoiceNumber'),
        containerProps: { width: '180px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('total'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('status'),
        containerProps: { width: '115px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: '',
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (returnedItems && returnedItems?.length) {
      return returnedItems?.map((item, index) => {
        return (
          <TableReturnedItemsRow
            returnedItems={returnedItems}
            key={item?.id || index.toString()}
            itemRow={item}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
