import React, { useEffect, useState } from 'react';
import { useLocalization } from '../../localization';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { ExpectedTab } from './components/ExpectedTab/ExpectedTab';
import { OrderTab } from './components/OrderTab/OrderTab';
import { ReturnTab } from './components/ReturnTab/ReturnTab';
import { ReturnsTab } from './components/ReturnsTab/ReturnsTab';
import { WarehouseTab } from './components/WarehouseTab/WarehouseTab';
import { ScanerButton } from './components/ScanerButton';
import { TableChangeButton } from './components/TableChangeButton';
import { ScanModal } from './components/PrintModal/ScanModal';
import { PreDeliveryModal } from './components/OrderTab/components/PreDelivery/PreDeliveryModal';
import { usePreDeliveryStateQuery } from '../../graphql/cacheQuery/__generated__/preDeliveryState';
import { clearDataPreDelivery } from '../../graphql/store/helper/js';
import { useSearchParams } from 'react-router-dom';

export const WarehousePage = () => {
  const { translateLang } = useLocalization();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { data: dataPreDeliveryState } = usePreDeliveryStateQuery();
  const [activeTable, setActiveTable] = useState<string>('expected');
  const tabs = [
    { id: 1, value: 'expected', notification: 0 },
    { id: 2, value: 'order', notification: 0 },
    { id: 3, value: 'returnToDelivery', notification: 0 },
    { id: 4, value: 'returns', notification: 0 },
    { id: 5, value: 'warehouse', notification: 0 },
  ];
  const [searchParams, setSearchParams] = useSearchParams({ tab: activeTable });
  const handleChange = (val: string | undefined) => {
    if (val && searchParams.get('tab') !== val) {
      setSearchParams({ tab: val });
    }
    if (val) {
      setActiveTable(val);
    }
  };

  useEffect(() => {
    setSearchParams({ tab: activeTable });
    return () => {
      clearDataPreDelivery();
    };
  }, []);

  return (
    <>
      <WrapperPages isButton={false} titleHeader={translateLang('warehouse')}>
        <Stack direction="row" justifyContent="space-between" height="48px" marginBottom="30px">
          <TableChangeButton
            activeTable={searchParams.get('tab') ?? activeTable}
            tabs={tabs}
            handleChange={handleChange}
          />
          {searchParams.get('tab') === 'expected' && (
            <ScanerButton handleClick={() => setOpenModal(true)} />
          )}
        </Stack>

        <Stack>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              placeContent: 'center',
            }}
          >
            {searchParams.get('tab') === 'expected' && <ExpectedTab />}
            {!searchParams.get('tab') && <ExpectedTab />}
            {searchParams.get('tab') === 'order' && <OrderTab />}
            {searchParams.get('tab') === 'returnToDelivery' && <ReturnTab />}
            {searchParams.get('tab') === 'returns' && <ReturnsTab />}
            {searchParams.get('tab') === 'warehouse' && <WarehouseTab />}
          </Box>
        </Stack>
      </WrapperPages>
      {openModal && <ScanModal open={openModal} handleClose={() => setOpenModal(false)} />}
      {dataPreDeliveryState?.preDeliveryState.isOpenModal && (
        <PreDeliveryModal
          open={dataPreDeliveryState?.preDeliveryState.isOpenModal}
          handleClose={() => {
            clearDataPreDelivery();
          }}
        />
      )}
    </>
  );
};
