import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../../components/TableProduct/TableProduct';
import { useNavigate } from 'react-router-dom';

import { useLocalization } from '../../../localization';
import { Delete, Info, LaunchRounded, RemoveRedEyeOutlined } from '@mui/icons-material/';
import { DeleteModal } from '../../ClientInfo/components/Modals/DeleteModal';
import { OrdersType, PaginationType } from '../../../graphql/queries/hook/useGetOrders';
import { PatchesName, RolesEnum } from '../../../types/types';
import { DeliveryAddressSelect } from '../../Delivery/components/AllOrders/components/tablesComponents/DeliveryAddressSelect';
import { formatDate } from '../../../helpers/functions';
import { format, isSameDay, parseISO } from 'date-fns';
import { DeliveryDateChangeModal } from '../../Delivery/components/AllOrders/components/DeliveryDateChangeModal';
import { useGetOrderItems } from '../../../graphql/queries/hook/useGetOrderItems';
import { OrderItemType } from '../../../helpers/types';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useDeleteOrderMutation } from '../../../graphql/mutations/__generated__/deleteOrder';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';

interface Props {
  pageCount: number;
  orders?: OrdersType[];
  pagination: PaginationType | undefined;
  setPage?: Dispatch<SetStateAction<number>>;
  changePage?: (val: number) => void;
  loadingOrders?: boolean;
  refetch: () => void;
}

export const TableOrders: FC<Props> = ({
  orders,
  pagination,
  setPage,
  changePage,
  pageCount,
  loadingOrders,
  refetch,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [id, setId] = useState<string | null>('0');
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modal, setModal] = useState<{
    isOpen: boolean;
    id: string | null;
    deliveryTime: string | Date | null;
  }>({
    isOpen: false,
    id: null,
    deliveryTime: null,
  });

  const { role } = useGetMe();

  const openDeliveryModal = (id: string, deliveryTime: string | Date | null) => {
    setModal({ isOpen: true, id, deliveryTime });
  };

  const closeDeliveryModal = () => {
    setModal({ isOpen: false, id: null, deliveryTime: null });
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const changePageNumber = (val: number) => {
    if (setPage) {
      setPage(val);
    }
    if (changePage) {
      changePage(val);
    }
  };
  const useHoverOrderItems = () => {
    const getOrderItemsForHover = (id: string | null) => {
      setId(id);
    };
    const { orderItems, loading: orderItemsLoading, refetch } = useGetOrderItems(id ?? '');
    return { orderItems, getOrderItemsForHover, orderItemsLoading, refetch };
  };
  const [deleteRunOrder] = useDeleteOrderMutation({
    variables: {
      id: id ?? '',
    },
  });
  const { orderItems, getOrderItemsForHover, orderItemsLoading } = useHoverOrderItems();
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('numberOrder'),
        containerProps: { width: '50px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('orderDate'),
        containerProps: { width: '50px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('client'),
        containerProps: { width: '200px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('manager'),
        containerProps: { width: '100px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('total'),
        containerProps: { width: '80px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('status'),
        containerProps: { width: '100px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('address'),
        containerProps: { width: '250px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('deliveryTime'),
        containerProps: { width: '360px', color: '#f1f3f8', align: 'center' },
      },
      { title: '', containerProps: { width: '20px', color: '#f1f3f8', align: 'center' } },
    ];
  };

  const deleteOrder = async () => {
    try {
      await deleteRunOrder({ variables: { id: id ?? '' } });
      closeModal();
      addNotification({
        typeMessage: 'success',
        messageError: translateLang('orderSuccesfullyDeleted'),
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const openDeleteModal = (item: OrdersType) => {
    if (item.status === 'editing') {
      addNotification({
        typeMessage: 'warning',
        messageError: translateLang('cantDeleteEditingStatus'),
      });
    } else {
      setId(item.id ?? '');
      setOpenModal(true);
    }
  };

  const getRowsTable = (): Array<TableCellsValue> => {
    if (orders && orders?.length) {
      return orders?.map(item => {
        const managerCredential = item.managerCredential
          ?.filter(item => !!item.firstName && !!item.lastName)
          .map(i => {
            const lastName = i?.lastName || '';
            const firstName = i?.firstName || '';
            const userName = i?.userName || '';
            let customerCredential = `${firstName} ${lastName}`;
            if (!lastName && !firstName) {
              customerCredential = userName;
            }
            return customerCredential;
          });

        const values: Array<BoxCell> = [];
        values.push({
          val: (
            <Tooltip
              title={
                orderItemsLoading ? null : (
                  <Box>
                    {orderItems?.length ? (
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>{translateLang('article')}</TableCell>
                              <TableCell>{translateLang('productName')}</TableCell>
                              <TableCell>{translateLang('price')}</TableCell>
                              <TableCell>{translateLang('quantity')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderItems?.map(item => (
                              <TableRow key={item.id}>
                                <TableCell>{item.article}</TableCell>
                                <TableCell>{item.productName}</TableCell>
                                <TableCell align="center">{item.customerPrice}</TableCell>
                                <TableCell align="center">
                                  {(item?.quantity ?? 0) - (item?.returnedQuantity ?? 0)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <TranslatedField
                        sx={{ padding: 1 }}
                        isTranslate
                        originText="orderDoesNotContainAnyItems"
                      />
                    )}
                  </Box>
                )
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: 'none',
                    backgroundColor: 'white',
                    color: 'black',
                    boxShadow: 3,
                    border: '1px solid #ccc',
                  },
                },
              }}
              placement="top-end"
            >
              <Box
                sx={{ cursor: 'pointer', display: 'flex', gap: '2px', alignItems: 'center' }}
                onMouseEnter={() => item.id && getOrderItemsForHover(item?.id)}
              >
                <Info style={{ fontSize: '14px' }} />
                {item.orderNumber}
              </Box>
            </Tooltip>
          ),
          containerProps: { height: '20px', borderLeft: 'none' },
        });
        values.push({ val: formatDate(item.date as string) });
        values.push({ val: item?.customerCredential });
        values.push({
          val: managerCredential?.join(', '),
        });
        values.push({
          val: `${item.total} \u20AC`,
        });
        values.push({
          val: translateLang(item.status as string),
          containerProps: { borderRight: 'solid 0.2px #8D8D8D' },
        });
        values.push({
          val: (
            <DeliveryAddressSelect
              customerId={item.customerId ?? ''}
              orderId={item.id ?? ''}
              value={item.deliveryAddress}
            />
          ),
          containerProps: { borderLeft: 'none' },
        });
        values.push({
          val: (
            <Box display="flex" alignItems="center">
              <Typography sx={{ color: 'black' }}>
                {item?.deliveryTime
                  ? formatDate(new Date(item?.deliveryTime as string), true)
                  : translateLang('enterDate')}
              </Typography>
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={() => {
                    openDeliveryModal(item?.id ?? '', item?.deliveryTime ?? '');
                  }}
                >
                  <LaunchRounded sx={{ fontSize: '0.8rem', color: '#5269A3' }} />
                </IconButton>
              </Box>
            </Box>
          ),
        });
        values.push({
          val: (
            <Box display="flex" gap={1}>
              <Box bgcolor="#e7eef4" borderRadius={2}>
                <IconButton
                  onClick={() => navigate(`/${PatchesName.Orders}/${item.customerId}/${item.id}`)}
                >
                  <RemoveRedEyeOutlined fontSize="small" />
                </IconButton>
              </Box>
              <Box bgcolor="#e7eef4" borderRadius={2}>
                <IconButton
                  disabled={role !== RolesEnum.Admin}
                  onClick={() => openDeleteModal(item)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ),
          containerProps: { borderLeft: 'none' },
        });
        return { cellsValues: values };
      });
    }
    return [];
  };

  return (
    <>
      <Box boxSizing="border-box">
        <TableProduct
          startPage={pagination?.page || 0}
          totalPages={pagination?.total ? Math.ceil(pagination.total / pageCount) : 0}
          itemsRows={getRowsTable()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePageNumber}
          loading={loadingOrders}
        />
      </Box>
      {modal && (
        <DeliveryDateChangeModal
          orderId={modal.id ?? ''}
          isOpen={modal.isOpen}
          date={format(
            modal?.deliveryTime ? new Date(modal?.deliveryTime as string) : new Date(),
            'P HH:mm'
          )}
          closeModal={closeDeliveryModal}
        />
      )}
      <DeleteModal
        openModal={openModal}
        closeModal={closeModal}
        title={translateLang('deleteOrder')}
        text={translateLang('wantDeleteOrder')}
        handlerDelete={deleteOrder}
      />
    </>
  );
};
