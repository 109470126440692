import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { useLocalization } from '../../../../localization';
import {
  Enum_Componentorderstatuseditinghistory_Status,
  OrderFiltersInput,
} from '../../../../__generated__/types';
import { Autocomplete, Box, Stack, TextField, UseAutocompleteProps } from '@mui/material';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';

interface Props {
  setResetFilter(): void;
  result: string | number | undefined;
  setFilter: Dispatch<SetStateAction<OrderFiltersInput | undefined>>;
  changePage(page: number): void;
  currentValueStatus: OrderStatusEditingHistory_Status | null;
  setCurrentValueStatus: Dispatch<SetStateAction<OrderStatusEditingHistory_Status | null>>;
}

export type OrderStatusEditingHistory_Status = {
  label: Enum_Componentorderstatuseditinghistory_Status;
  value: string;
};

export const ToolBarCompletedOrderTab: FC<Props> = ({
  result,
  setFilter,
  changePage,
  currentValueStatus,
  setCurrentValueStatus,
}) => {
  const { translateLang } = useLocalization();
  // const [currentValueStatus, setCurrentValueStatus] =
  //   useState<OrderStatusEditingHistory_Status | null>(null);

  const WareHouseStatus: OrderStatusEditingHistory_Status[] = [
    ...[
      Enum_Componentorderstatuseditinghistory_Status.InStock,
      Enum_Componentorderstatuseditinghistory_Status.Awaiting,
    ].map(value => ({
      label: value,
      value: translateLang(value),
    })),
  ];

  const handlerClearFilter = () => {
    changePage(0);
    setCurrentValueStatus(null);
    setFilter(oldFilter => {
      const _filter = { ...oldFilter };
      delete _filter?.status;
      if (Object.keys(_filter).length > 0) {
        return _filter;
      }
      return undefined;
    });
  };
  const handleChangeStatus: UseAutocompleteProps<
    OrderStatusEditingHistory_Status,
    undefined,
    undefined,
    undefined
  >['onChange'] = (evt, option): void => {
    if (option) {
      setCurrentValueStatus(option as OrderStatusEditingHistory_Status);
      changePage(0);
      setFilter(oldFilter => {
        return {
          ...oldFilter,
          status: {
            eq: (option as OrderStatusEditingHistory_Status).label,
          },
        };
      });
    }
  };

  return (
    <WrapperToolBar>
      <Stack width="100%" flexDirection="row" justifyContent="left">
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || ''}
        />
      </Stack>
      <Stack spacing={2} bgcolor="white" flexDirection="row" justifyContent="right">
        <Stack direction="row" gap={2} alignItems="center">
          <TranslatedField originText={translateLang('status')} />
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ minWidth: 300 }}
            value={currentValueStatus}
            options={WareHouseStatus || []}
            renderInput={params => <TextField {...params} label={translateLang('status')} />}
            getOptionLabel={option => {
              return option?.value || '';
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.label}>
                {option.value}
              </Box>
            )}
            onChange={handleChangeStatus}
            onInputChange={(e, value, reason) => {
              if (reason === 'clear') {
                handlerClearFilter();
              }
            }}
          />
        </Stack>
      </Stack>
    </WrapperToolBar>
  );
};
