import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useGetSupplierBalanceLazyQuery } from '../../../graphql/queries/__generated__/getSupplierBalance';
import { Add } from '@mui/icons-material';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { CreateNewSupplier } from '../../Accounting/components/Suppliers/CreateNewSupplierDialog';
import { useGetSuppliers } from '../../../graphql/queries/hook/useGetSuppliers';

interface SuppliersType {
  supplierBalance?: number | null;
  remainingPayment?: number | null;
  supplierName: string | null | undefined;
  id?: string | null;
}

export const ProvidersTab = () => {
  const { refetch } = useGetSuppliers({
    or: [{ blocked: { eq: false } }, { blocked: { eq: null } }],
  });

  const [isOpenCreateSupplierDialog, setIsOpenCreateSupplierDialog] = useState(false);
  return (
    <Box width="100%" display="flex" flexDirection="column" gap={2}>
      <Button
        variant="contained"
        startIcon={<Add />}
        sx={{
          height: 50,
          p: 2,
          textTransform: 'none',
          backgroundColor: '#5269A3',
          borderRadius: '10px',
          alignSelf: 'flex-end',
        }}
        onClick={() => {
          setIsOpenCreateSupplierDialog(true);
        }}
      >
        <TranslatedField originText={'createSupplier'} fontSize={16} isTranslate noWrap />
      </Button>

      <CreateNewSupplier
        refetchSuppliers={refetch}
        isModalOpen={isOpenCreateSupplierDialog}
        handleClose={() => setIsOpenCreateSupplierDialog(false)}
      />
    </Box>
  );
};
