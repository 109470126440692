import { endOfToday, startOfMonth } from 'date-fns';

import {
  CustomerReturnItemFiltersInput,
  Enum_Customerreturn_Status,
  Pagination,
  PaginationArg,
} from '../../../../../__generated__/types';
import { useGetCustomerReturnsForWarehouseQuery } from '../../../../../graphql/queries/__generated__/getCustomerReturnsForWarehousePage';
import { useState } from 'react';
import { PAGE_COUNT } from '../../../../../helpers/constants';
import { useSearchParams } from 'react-router-dom';

type GetUserFullNameParams = {
  attributes?: {
    username?: string | null;
    customer_contact_info?: {
      data?: {
        attributes?: {
          lastName?: string | null;
          firstName?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export interface ReturnType {
  data?: GetUserFullNameParams;
  returnId: string | null;
  id?: string | null;
  correctiveInvoiceDate: any;
  correctiveInvoiceNumber?: string | null;
  dateOfReceiving: any;
  dateReturned: any;
  status?: Enum_Customerreturn_Status | null;
  returnReason?: string | null;
  returnComment?: string | null;
  name?: string;
  surname?: string;
  dateIncoming?: Date | null;
  total?: number | null;
  fullName?: string;
}

export type PaginationTransactionsType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  customerReturns: Array<ReturnType>;
  pagination?: PaginationTransactionsType;
  isLoading?: boolean;
  refetchCustomerReturns: () => void;
  changePage: (_page: number) => void;
  totalPage: number;
}

export type FiltersType = {
  searchStrings?: string;
  searchNumbers?: string;
};
interface PropsHook {
  pagination?: PaginationArg;
  filters: CustomerReturnItemFiltersInput;
}

export const useGetClientReturnsForWareHouseQuery = ({ filters }: PropsHook): ReturnHook => {
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: clientReturnsData,
    loading: clientReturnsLoading,
    refetch: refetchCustomerReturns,
  } = useGetCustomerReturnsForWarehouseQuery({
    variables: {
      filters: filters,
      pagination: {
        page: page,
        pageSize: PAGE_COUNT,
      },
      sort: ['dateReturned:asc', 'id:asc'],
    },
  });

  const changePage = (_page: number): void => {
    setPage(_page + 1);
    setSearchParams(prev => {
      const params = new URLSearchParams(prev);
      params.set('page', String(_page + 1));
      return params;
    });
  };
  if (clientReturnsData?.customerReturns?.data) {
    const returnsData = clientReturnsData?.customerReturns.data;
    const customerReturns = returnsData.map(item => {
      return {
        data: {
          attributes: {
            username: item.attributes?.customer?.data?.attributes?.username || null,
            customer_contact_info:
              item.attributes?.customer?.data?.attributes?.customer_contact_info,
          },
        },
        name:
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.firstName ?? '',
        surName:
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.firstName ?? '',
        returnId: item?.id ?? '',
        id: item.attributes?.customer?.data?.id ?? '',
        total: item.attributes?.total || 0,
        dateOfReceiving:
          item?.attributes?.customer_return_items?.data[0]?.attributes?.stock?.data?.attributes
            ?.incoming_invoice?.data?.attributes?.supplierInvoiceDate ?? '',
        correctiveInvoiceDate: item?.attributes?.correctiveInvoiceDate ?? '',
        correctiveInvoiceNumber: item.attributes?.correctiveInvoiceNumber ?? '',
        dateReturned: item.attributes?.dateReturned ?? '',
        status: item.attributes?.status ?? Enum_Customerreturn_Status.Created,
        returnReason:
          item?.attributes?.customer_return_items?.data[0]?.attributes?.returnReason ?? '',
        returnComment:
          item?.attributes?.customer_return_items?.data[0]?.attributes?.returnComment ?? '',
      };
    });

    const pagination: PaginationTransactionsType = {
      page: clientReturnsData?.customerReturns.meta.pagination.page,
      pageCount: clientReturnsData?.customerReturns.meta.pagination.pageCount,
      pageSize: clientReturnsData?.customerReturns.meta.pagination.pageSize,
      total: clientReturnsData?.customerReturns.meta.pagination.total || 0,
    };
    return {
      customerReturns,
      pagination,
      isLoading: clientReturnsLoading,
      refetchCustomerReturns,
      changePage,
      totalPage: clientReturnsData?.customerReturns.meta.pagination.total
        ? Math.ceil(clientReturnsData?.customerReturns.meta.pagination.total / PAGE_COUNT)
        : 0,
    };
  }

  return {
    customerReturns: [],
    pagination: undefined,
    isLoading: clientReturnsLoading,
    refetchCustomerReturns,
    changePage,
    totalPage: 0,
  };
};
