import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { EnterCodeInput } from './EnterCodeInput';
import { ButtonOrange } from './ButtonOrange';
import { ButtonTimer } from './ButtonTimer';
import { useLocation } from 'react-router-dom';
import { OptionLoginType, SuccessType } from '../helpers/types';
import { handlerError } from '../../../helpers/functions';
import { useLocalization } from '../../../localization';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { useConfirmPhoneMutation } from '../../../graphql/mutations/__generated__/confirmPhone';
import { useRegisterByPhoneMutation } from '../../../graphql/mutations/__generated__/registerByPhone';
import { useHelpersNavigate } from '../hooks/helpersNavigate';
import { WrapperShadow } from './WrapperShadow';
import { useGetIsMobileQuery } from '../../../graphql/cacheQuery/__generated__/getIsMobile';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface Props {
  toggleConfirmForm?(): void;
  phone?: string;
  password?: string;
}

export const EnterCodeForm = ({ toggleConfirmForm, phone, password }: Props) => {
  const [currentCode, setCurrentCode] = useState('');
  const [errorCode, setErrorCode] = useState(false);
  const stateLocation = useLocation().state as OptionLoginType;
  const { translateLang } = useLocalization();
  const { data } = useGetIsMobileQuery();
  const { addNotification } = useHandlerNotificationApp();
  const { goToRegisterPage, goToSuccessPage } = useHelpersNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [runConfirmPhone, { loading: loadConfirm }] = useConfirmPhoneMutation();
  const [runRegisterByPhone, { loading: loadByPhone }] = useRegisterByPhoneMutation();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('registerByPhone');
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handlerButton = async () => {
    if (!stateLocation?.phone && !phone) {
      return;
    }
    try {
      const response = await runConfirmPhone({
        variables: {
          input: {
            code: currentCode.replace(/[^0-9]/g, ''),
            phoneNumber: phone ? `+${phone}` : `+${stateLocation.phone}`,
          },
        },
        refetchQueries: 'active',
      });
      if (response.data?.confirmPhoneNumber?.user) {
        toggleConfirmForm
          ? toggleConfirmForm()
          : goToSuccessPage({ state: { isPhone: true, successType: SuccessType.Register } });
      }
    } catch (err: any) {
      setErrorCode(true);
      addNotification({
        messageError: err?.message,
        typeMessage: 'error',
      });
      handlerError(err);
    }
  };
  const geSubTitle = () => {
    const sendNumber = translateLang('sendNumber');
    if (phone) {
      return `${sendNumber}  ${phone}`;
    }
    if (stateLocation?.phone) {
      return `${sendNumber}  ${stateLocation.phone}`;
    }
    return sendNumber;
  };
  const isDisableButton = () => {
    return currentCode.length < 8;
  };
  const handlerTextClick = () => {
    goToRegisterPage({
      state: { phone: stateLocation.phone, password: stateLocation.password, isPhone: true },
    });
  };
  const reacquireCode = async () => {
    const token = await handleReCaptchaVerify();
    if (phone && password) {
      try {
        await runRegisterByPhone({
          variables: {
            input: {
              phoneNumber: `+${phone}`,
              password: password,
              token: token || '',
            },
          },
        });
      } catch (err: unknown) {
        handlerError(err);
      }
    }
    if (stateLocation?.phone && stateLocation?.password) {
      try {
        await runRegisterByPhone({
          variables: {
            input: {
              phoneNumber: `+${stateLocation.phone}`,
              password: stateLocation.password,
              token: token || '',
            },
          },
        });
      } catch (err: unknown) {
        handlerError(err);
      }
    }
  };

  if (!stateLocation?.phone && !phone) {
    return (
      <WrapperShadow title={translateLang('enterCode')} height={data?.isMobile ? '100vh' : '300px'}>
        <Typography
          fontSize="18px"
          lineHeight="22px"
          textAlign="center"
          mt={5}
          sx={{ cursor: 'pointer' }}
        >
          {translateLang('somethingWentWrong')}
        </Typography>
      </WrapperShadow>
    );
  }
  return (
    <WrapperShadow title={translateLang('enterCode')} subTitle={geSubTitle()}>
      <Box width="100%" mt={7}>
        <EnterCodeInput
          code={currentCode}
          error={errorCode}
          setCurrentCode={setCurrentCode}
          setErrorCode={setErrorCode}
        />
      </Box>
      <ButtonOrange
        type="button"
        titleButton={translateLang('send')}
        handlerClickButton={handlerButton}
        disableButton={isDisableButton()}
        loading={loadConfirm}
      />
      <ButtonTimer handlerButton={reacquireCode} loading={loadByPhone} />
      {!phone && (
        <Box onClick={handlerTextClick}>
          <Typography
            fontSize="18px"
            lineHeight="22px"
            textAlign="center"
            mt={5}
            sx={{ cursor: 'pointer' }}
          >
            {translateLang('differentPhone')}
          </Typography>
        </Box>
      )}
    </WrapperShadow>
  );
};
