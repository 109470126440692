import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, Divider, Fade, Stack } from '@mui/material';

import { SubCategory } from './SubCategory';
import { CategoryButton } from './CategoryButton';
import { BottomNavigate } from './BottomNavigate';
import { GeneralSparesFiltersInput } from '../../../__generated__/types';
import { useNavigate } from 'react-router-dom';

export interface CategoryProps {
  filters: GeneralSparesFiltersInput | undefined;
  handleSetFilters: (filters: GeneralSparesFiltersInput | undefined) => void;
  dataCategories:
    | ({
        __typename?: 'CategoryTree' | undefined;
        id?: string | null | undefined;
        name?: string | null | undefined;
        parentId?: string | null | undefined;
        level?: number | null | undefined;
      } | null)[]
    | undefined;
  params?: URLSearchParams | null;
  category: string;
  subCategory: string;
  checked: boolean;
  currentActiveMenu: {
    id: string | null | undefined;
    name: string | null | undefined;
  };
  setCurrentActiveMenu: (currentActiveMenu: { id: string; name: string }) => void;
  setCategory: (category: string) => void;
  setSubCategory: (subCategory: string) => void;
  setChecked: (setChecked: boolean) => void;
}

export const CategoryList: FC<CategoryProps> = ({
  dataCategories,
  filters,
  handleSetFilters,
  params,
  category,
  subCategory,
  currentActiveMenu,
  setCurrentActiveMenu,
  setCategory,
  setSubCategory,
  checked,
  setChecked,
}) => {
  const categoryRef = useRef(null);
  const navigate = useNavigate();

  const getSecondCategory = (
    tecdoc_categoryId: string,
    nameSubCategory: string,
    nameCategory: string
  ) => {
    const filter: GeneralSparesFiltersInput = {
      ...filters,
      tecdoc_categoryId,
    };
    const newParams = new URLSearchParams(params?.toString());
    handleSetFilters(filter);
    setCategory(nameCategory);
    setSubCategory(nameSubCategory);
    if (nameCategory) {
      newParams?.set('category', nameCategory);
      newParams?.set('subCategory', nameSubCategory);
    } else {
      newParams?.set('subCategory', nameSubCategory);
      newParams.delete('category');
    }
    newParams?.set('currentActiveMenu', currentActiveMenu.name ?? '');
    newParams?.set('currentActiveMenuId', currentActiveMenu.id ?? '');
    navigate(`?${newParams?.toString()}`);
  };
  const handlerOpenMenuList = (id: string, name: string) => {
    const newParams = new URLSearchParams(params?.toString());
    setCurrentActiveMenu({ id, name });
    setChecked(true);
    const _filters = { ...filters };
    handleSetFilters(_filters);
    navigate(`?${newParams?.toString()}`);
  };

  const clearFilter = () => {
    const newParams = new URLSearchParams(params?.toString());
    setCategory('');
    setSubCategory('');
    setChecked(false);
    setCurrentActiveMenu({
      id: '',
      name: '',
    });
    newParams.delete('tecdocCategoryId');
    newParams.delete('currentActiveMenu');
    newParams.delete('currentActiveMenuId');
    newParams.delete('subCategory');
    newParams.delete('category');

    const _filters = { ...filters };
    delete _filters.tecdoc_categoryId;

    handleSetFilters(_filters);
    navigate(`?${newParams.toString()}`, { replace: true });
  };
  useEffect(() => {
    const currentActiveMenuName = params?.get('currentActiveMenu') ?? dataCategories?.[0]?.name;
    const currentActiveMenuId = params?.get('currentActiveMenuId') ?? dataCategories?.[0]?.id;
    setCurrentActiveMenu({
      id: currentActiveMenuId ?? '',
      name: currentActiveMenuName ?? '',
    });
    setCategory(params?.get('category') ?? '');
    setSubCategory(params?.get('subCategory') ?? '');
  }, []);

  useEffect(() => {
    if (
      dataCategories?.[0]?.id &&
      !dataCategories?.some(item => item?.id === currentActiveMenu.id)
    ) {
      setCurrentActiveMenu({
        id: dataCategories?.[0]?.id ?? '',
        name: dataCategories?.[0]?.name ?? '',
      });
    }
  }, [dataCategories?.[0]?.id, currentActiveMenu.id]);

  return (
    <>
      <Box
        sx={{
          height: '410px',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: '10px',
        }}
        position="relative"
      >
        <Stack
          divider={<Divider variant="middle" orientation="vertical" flexItem />}
          ref={categoryRef}
          direction="row"
          height="56px"
          textAlign="center"
          mt="7px"
          borderRadius="10px"
          sx={{ placeContent: 'space-between' }}
        >
          {dataCategories?.map(item => {
            return (
              <Fragment key={item?.id}>
                <CategoryButton
                  categoryName={item?.name || ''}
                  currentActiveMenu={currentActiveMenu}
                  onSetCategory={handlerOpenMenuList}
                  indexCategory={`${item?.id}`}
                />

                {currentActiveMenu?.id === item?.id && (
                  <Fade in={true} style={{ transitionDelay: checked ? '200ms' : '0ms' }}>
                    <Stack
                      direction="row"
                      padding="10px"
                      flexWrap="wrap"
                      maxHeight="312px"
                      overflow="auto"
                      justifyContent="space-between"
                      marginTop="56px"
                      display="flex"
                      position="absolute"
                      marginBottom="100px"
                      width="80%"
                      sx={{
                        mX: '10px',
                        backgroundColor: 'white',
                        borderBottom: '5px',
                        wordBreak: 'break-all',
                        '&::-webkit-scrollbar': {
                          width: '6px',
                        },
                      }}
                    >
                      <SubCategory
                        idCategory={item?.id}
                        category={category}
                        secondCategory={getSecondCategory}
                      />
                    </Stack>
                  </Fade>
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Box>
    </>
  );
};
