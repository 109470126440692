import React, { useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { addDays, endOfToday, endOfDay, format, startOfDay, startOfMonth, subDays } from 'date-fns';
import { useLocalization } from '../../../../localization';
import { SearchField } from '../../../../components/SearchField';
import { StockReturnedFromCustomerFiltersInput } from '../../../../__generated__/types';
import { useGetClientReturnsForWareHouseQuery } from './hooks/useGetCustomerReturnsForWarehouseQuery';
import { ReturnsTabTable } from './ReturnsTabTable';
import { ToolBarReturnsTab } from './ToolBarReturnsTab';
import { CreateUpdateReturnModal } from './CreateUpdateReturnModal';
import { useSearchParams } from 'react-router-dom';

export const ReturnsTab = () => {
  const { translateLang } = useLocalization();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState('');
  const [isEdit, setEdit] = useState<boolean>(false);
  const [openReturnModal, setOpenReturnModal] = useState<boolean>(false);
  const [filter, setFilter] = useState<StockReturnedFromCustomerFiltersInput>({
    dateReturned: {
      between: [
        format(startOfDay(subDays(new Date(), 30)), 'yyyy-MM-dd'),
        format(endOfDay(new Date()), 'yyyy-MM-dd'),
      ],
    },
  });
  const { customerReturns, pagination, isLoading, changePage, totalPage } =
    useGetClientReturnsForWareHouseQuery({
      filters: filter,
    });
  const closeReturnModal = () => {
    setOpenReturnModal(false);
  };
  useEffect(() => {
    if (searchParams.get('searchText')) {
      setSearchText(searchParams.get('searchText') ?? '');
      setFilter(oldFilter => {
        return {
          ...oldFilter,
          or: [
            { id: { containsi: searchParams.get('searchText') } },
            {
              customer: {
                customer_contact_info: {
                  or: [
                    { firstName: { containsi: searchParams.get('searchText') } },
                    { lastName: { containsi: searchParams.get('searchText') } },
                  ],
                },
              },
            },
            { correctiveInvoiceNumber: { containsi: searchParams.get('searchText') } },
          ],
        };
      });
    }
  }, []);
  const changeSearchFilter = (text: string) => {
    changePage(0);
    if (text) {
      setSearchParams(prev => {
        const params = new URLSearchParams(prev);
        params.set('searchText', text);

        return params;
      });
      setFilter(oldFilter => {
        return {
          ...oldFilter,
          or: [
            { id: { containsi: text } },
            {
              customer: {
                customer_contact_info: {
                  or: [{ firstName: { containsi: text } }, { lastName: { containsi: text } }],
                },
              },
            },
            { correctiveInvoiceNumber: { containsi: text } },
          ],
        };
      });
    } else {
      const _filter = { ...filter };
      delete _filter?.or;
      setFilter(_filter);
      setSearchText('');
      setSearchParams(prev => {
        const params = new URLSearchParams(prev);
        params.delete('searchText');
        return params;
      });
    }
  };

  return (
    <Stack width="100%" paddingBottom={10}>
      <Box style={{ display: 'flex', justifyContent: 'end', marginBottom: '50px' }}>
        <Button
          style={{ width: '220px' }}
          disabled={true}
          variant="contained"
          onClick={() => setOpenReturnModal(true)}
        >
          {translateLang('createReturn')}
        </Button>
      </Box>
      <SearchField
        executeWithDelay={changeSearchFilter}
        placeholder={translateLang('search_returns')}
        initStateText={searchText}
      />
      <ToolBarReturnsTab
        result={pagination?.total || 0}
        setFilterSubscription={setFilter}
        changePage={changePage}
      />

      <ReturnsTabTable
        returnedItems={customerReturns}
        loading={isLoading}
        changePage={changePage}
        currentPage={Number(searchParams.get('page')) || pagination?.page}
        totalPage={totalPage}
      />
      <CreateUpdateReturnModal isEdit={isEdit} open={openReturnModal} close={closeReturnModal} />
    </Stack>
  );
};
